import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import {
  Button,
  Dialog,
  IconButton,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import { DataGrid, GridRowId, jaJP } from '@mui/x-data-grid';
import { FC, memo } from 'react';
import PatientsColumns from 'src/columns/Patients.columns';
import { PatientResponseDto } from 'src/dto/Patient.response.dto';
import PatientForm from 'src/forms/Patient.form';
import QuestionForm from 'src/forms/Question.form';

import PatientsToolbarPresenter from './Patients.toolbar.presenter';

export type Props = {
  patients: PatientResponseDto[];
  updateSelectedPatientIds: (ids: number[]) => void;
  loading: boolean;
  navigateTo: (id: number) => void;
  navigateToAdmins: () => void;
  logoutButtonOnClick: () => void;
  newPatientDialogIsOpen: boolean;
  openNewPatientDialog: () => void;
  closeNewPatientDialog: () => void;
  closeNewQuestionDialog: () => void;
  newQuestionDialogIsOpen: boolean;
  updateLoading: (bool: boolean) => void;
  deleteButtonOnClick: () => void;
  copyTextToClipboard: (text: string) => void;
  navigateToInvitations: () => void;
}

const PatientsPresenter: FC<Props> = memo((
  {
    patients,
    loading,
    navigateTo,
    logoutButtonOnClick,
    newPatientDialogIsOpen,
    openNewPatientDialog,
    closeNewPatientDialog,
    updateSelectedPatientIds,
    newQuestionDialogIsOpen,
    closeNewQuestionDialog,
    updateLoading,
    deleteButtonOnClick,
    navigateToAdmins,
    copyTextToClipboard,
    navigateToInvitations,
  }
) => (
  <>
    <Stack
      p={4}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mb={2}
      >
        <Stack
          direction="row"
          alignItems="center"
        >
          <IconButton
            onClick={logoutButtonOnClick}
            disabled={loading}
          >
            <MeetingRoomIcon />
          </IconButton>
          <Stack>
            <Typography>
              顧客一覧
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          mb={2}
        >
          <Button
            onClick={navigateToInvitations}
          >
            招待一覧
          </Button>
          <Button
            onClick={navigateToAdmins}
          >
            管理者一覧
          </Button>
        </Stack>
      </Stack>
      <DataGrid
        columns={PatientsColumns(navigateTo, copyTextToClipboard)}
        rows={patients}
        checkboxSelection
        loading={loading}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        components={{
            LoadingOverlay: LinearProgress,
            Toolbar: PatientsToolbarPresenter
          }}
        componentsProps={{
            toolbar: {
              openNewPatientDialog,
              isLoading: loading,
              deleteButtonOnClick
            }
          }}
        onRowSelectionModelChange={(gridRowIds: GridRowId[]) => {
            updateSelectedPatientIds(
              gridRowIds
                .map((it) => Number(it))
            );
          }}
      />
    </Stack>
    <Dialog
      open={newPatientDialogIsOpen}
      onClose={closeNewPatientDialog}
      maxWidth="sm"
      fullWidth
    >
      <PatientForm
        loading={loading}
        closeDialog={closeNewPatientDialog}
        updateLoading={updateLoading}
      />
    </Dialog>
    <Dialog
      open={newQuestionDialogIsOpen}
      onClose={closeNewQuestionDialog}
      maxWidth="sm"
      fullWidth
    >
      <QuestionForm
        loading={loading}
        updateLoading={updateLoading}
        closeDialog={closeNewQuestionDialog}
      />
    </Dialog>
  </>
  ));

export default PatientsPresenter;
