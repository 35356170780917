import axios from 'axios';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { QuestionRequestDto } from 'src/dto/Question.request.dto';
import { QuestionResponseDto } from 'src/dto/Question.response.dto';

const apiEndpoint = [
  'https://tokiomarine-api.staging.exawizards.ai',
  '/api/v1/admin/questions'
].filter((maybe) => maybe).join('');

const getRecords: (queryClient: QueryClient) => () => Promise<QuestionResponseDto[]> = (queryClient: QueryClient) => async () => {
  const data: Response = await fetch(apiEndpoint, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return data.json();
};

const getRecord: (id: number) => () => Promise<QuestionResponseDto> = (id: number) => async () => {
  const requestPath = `${apiEndpoint}/${id}`;

  const data: Response = await fetch(requestPath, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return data.json();
};

const createRequestBody = (dto: QuestionRequestDto) => ({
  name: dto.title,
  answers: dto.answers.map((it) => it.value)
  });

export const useQuestionsQuery = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ['questions'],
    getRecords(queryClient),
    {
      staleTime: Infinity
    }
  );
};

export const useQuestionQuery = (id: number) => useQuery(
  ['questions', { id }],
  getRecord(id),
  {
    staleTime: Infinity,
    enabled: Boolean(id)
  }
);

export const useMutateQuestion = () => ({
  post: useMutation(
    (dto: QuestionRequestDto) => axios.post(apiEndpoint, createRequestBody(dto))
  ),
  patch: useMutation(
    ({ dto, id }: {
      id: number;
      dto: QuestionRequestDto;
    }) => axios.post(`${apiEndpoint}/${id}`, createRequestBody(dto))
  )
});
