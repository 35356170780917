import { Button, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns-tz';

import { VideoResponseDto } from '../dto/Video.response.dto';

const PatientColumns: (allVideos: VideoResponseDto[], updateDisplayVideos: (dtos: VideoResponseDto[]) => void) => GridColDef[] = (allVideos, updateDisplayVideos) => [
  {
    field: 'actions',
    headerName: '操作',
    width: 100,
    align: 'center',
    renderCell: (params: { row: VideoResponseDto }) => (
      <Stack
        direction="row"
        gap={1}
      >
        <Button
          disabled={!params.row.analyzedVideoForAdminPath}
          size="small"
          onClick={() => {
            if (!params.row.analyzedVideoForAdminPath) return;

            updateDisplayVideos([params.row]);
          }}
        >
          動画表示
        </Button>
      </Stack>
    ),
  },
  { field: 'id', headerName: 'ID' },
  { field: 'analyzeStatus', headerName: '解析状態' },
  {
    field: 'createdAt',
    headerName: '撮影日',
    valueGetter: (params: { row: VideoResponseDto }) => params.row.createdAt && format(
      new Date(params.row.createdAt),
      'yyyy-MM-dd',
      {
        timeZone: 'Asia/Tokyo'
      }
    )
  },
  {
    field: 'neckPain',
    headerName: '首の痛み',
    type: 'number'
  },
  {
    field: 'neckPainBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
          .row
          .neckPain
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
          .neckPain
        ?? 0;

      return current - prev;
    }
  },
  {
    field: 'shoulderPain',
    headerName: '肩の痛み',
    type: 'number'
  },
  {
    field: 'shoulderPainBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
          .row
          .shoulderPain
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
          .shoulderPain
        ?? 0;

      return current - prev;
    }
  },
  {
    field: 'dailyLifeDegree',
    headerName: '生活への支障',
    type: 'number'
  },
  {
    field: 'dailyLifeDegreeBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
          .row
          .dailyLifeDegree
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
          .dailyLifeDegree
        ?? 0;

      return current - prev;
    }
  },
  {
    field: 'pitchFront',
    headerName: '屈曲／伸展(前)',
    type: 'number',
    width: 120,
    valueGetter: (params: { row: VideoResponseDto }) => params
      .row
      .score
      ?.pitch?.front
  },
  {
    field: 'pitchFrontBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
        .row
        .score
        ?.pitch
        ?.front
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
        .score
        ?.pitch
        ?.front
        ?? 0;

      return current - prev;
    }
  },
  {
    field: 'pitchBack',
    headerName: '屈曲／伸展(後)',
    type: 'number',
    width: 120,
    valueGetter: (params: { row: VideoResponseDto }) => params
      .row
      .score
      ?.pitch?.back
  },
  {
    field: 'pitchFBackBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
          .row
          .score
          ?.pitch
          ?.back
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
          .score
          ?.pitch
          ?.back
        ?? 0;

      return current - prev;
    }
  },
  {
    field: 'yawLeft',
    headerName: '回旋(左)',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => params
      .row
      .score
      ?.yaw?.left
  },
  {
    field: 'yawLeftBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
          .row
          .score
          ?.yaw
          ?.left
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
          .score
          ?.yaw
          ?.left
        ?? 0;

      return current - prev;
    }
  },
  {
    field: 'yawRight',
    headerName: '回旋(右)',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => params
      .row
      .score
      ?.yaw?.right
  },
  {
    field: 'yawRightBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
          .row
          .score
          ?.yaw
          ?.right
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
          .score
          ?.yaw
          ?.right
        ?? 0;

      return current - prev;
    }
  },
  {
    field: 'rollLeft',
    headerName: '屈側(左)',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => params
        .row
        .score
        ?.roll?.left
  },
  {
    field: 'rollLeftBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
          .row
          .score
          ?.roll?.left
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
          .score
          ?.roll?.left
        ?? 0;

      return current - prev;
    }
  },
  {
    field: 'rollRight',
    headerName: '屈側(右)',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => params
      .row
      .score
      ?.roll?.right
  },
  {
    field: 'rollRightBefore',
    headerName: '前回差',
    type: 'number',
    valueGetter: (params: { row: VideoResponseDto }) => {
      const current = params
          .row
          .score
          ?.roll?.right
        ?? 0;

      const currentId: number = params.row.id;
      const ids: number[] = allVideos.map((it) => it.id);
      const index = ids.indexOf(currentId);
      const maybePrev = allVideos[(index - 1)];

      if (maybePrev == null) return undefined;

      const prev = maybePrev
          .score
          ?.roll?.right
        ?? 0;

      return current - prev;
    }
  },
];

export default PatientColumns;
