import axios from 'axios';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';

import { VideoBulkCreationRequestDto } from '../dto/Video.bulk.creation.request.dto';
import { VideoResponseDto } from '../dto/Video.response.dto';

const getRecords: (patientId: number, queryClient: QueryClient) => () => Promise<VideoResponseDto[]> = (patientId: number, queryClient: QueryClient) => async () => {
  const requestPath = [
    'https://tokiomarine-api.staging.exawizards.ai',
    `/api/v1/admin/patients/${patientId}/videos`
  ].filter((maybe) => maybe).join('');

  const data: Response = await fetch(requestPath, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return data.json();
};

export const useVideosQuery = (patientId: number) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['videos', { patientId }],
    getRecords(patientId, queryClient),
    {
      staleTime: Infinity,
      enabled: Boolean(patientId)
    }
  );
};

export const useMutateVideo = () => ({
  bulkCreation: useMutation(
    (dto: VideoBulkCreationRequestDto) => axios.post('/api/v1/admin/videos/bulk/creation', dto)
  )
});
