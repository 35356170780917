import * as yup from 'yup';

const questionSchema = yup.object({
  title: yup.string().required('必須'),
  answers: yup.array(
    yup.object().shape({
      value: yup.string().required('必須'),
    }),
  ),
});

export default questionSchema;
