import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import ActivationComponent from 'src/components/Activation.component';
import ActivationPresenter from 'src/presenters/Activation.presenter';

const ActivationPage: FC = memo(() => {
  const params = useParams();

  const { token } = params;

  return (
    <ActivationComponent
      token={token}
      Presenter={ActivationPresenter}
    />
  );
});

export default ActivationPage;
