import { Backdrop, CircularProgress } from '@mui/material';
import { FC, memo } from 'react';

export type Props = {
  open: boolean;
}

const ActivationPresenter: FC<Props> = memo((
  {
    open
  }
) => (
  <Backdrop
    open={open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
));

export default ActivationPresenter;
