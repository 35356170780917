import { useQuery } from 'react-query';
import { ActivationRequestDto } from 'src/dto/Activation.request.dto';
import { ActivationResponseDto } from 'src/dto/Activation.response.dto';

const getRecord: (dto: ActivationRequestDto) => () => Promise<ActivationResponseDto> = (dto: ActivationRequestDto) => async () => {
  const requestPath = [
    'https://tokiomarine-api.staging.exawizards.ai',
    `/api/v1/activations/${dto.token}`
  ].filter((maybe) => maybe).join('');

  const data: Response = await fetch(requestPath, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return data.json();
};

export const useActivationQuery = (dto: ActivationRequestDto) => useQuery(
  ['activation'],
  getRecord(dto),
  {
    enabled: Boolean(dto?.token)
  }
);
