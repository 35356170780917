import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { FC, memo } from 'react';

type Props = {
  openNewPatientDialog: () => void;
  isLoading: boolean;
  deleteButtonOnClick: () => void;
}

const PatientsToolbarPresenter: FC<Props> = memo((
  {
    openNewPatientDialog,
    isLoading,
    deleteButtonOnClick
  }
) => (
  <GridToolbarContainer
    sx={{
        width: '100%'
      }}
  >
    <Stack
      direction="row"
      gap={1}
    >
      <LoadingButton
        loading={isLoading}
        onClick={deleteButtonOnClick}
        startIcon={<DeleteForeverIcon />}
      >
        削除する
      </LoadingButton>
    </Stack>
  </GridToolbarContainer>
  ));

export default PatientsToolbarPresenter;
