import { Button, ButtonGroup } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { PatientResponseDto } from '../dto/Patient.response.dto';

const PatientsColumns: (navigateTo: (id: number) => void, copyTextToClipboard: (text: string) => void) => GridColDef[] = (
  navigateTo,
  copyTextToClipboard
) => [
  {
    field: 'actions',
    headerName: '操作',
    flex: 1,
    renderCell: (params: { row: PatientResponseDto }) => (
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button
          onClick={() => {
            navigateTo(params.row.id);
          }}
        >
          詳細
        </Button>
      </ButtonGroup>
    ),
  },
  {
    field: 'token',
    headerName: 'ID(招待トークン)',
    valueGetter: (params: { row: PatientResponseDto }) => params.row.token ?? '',
    flex: 1
  },
  {
    field: 'email',
    headerName: 'メールアドレス',
    valueGetter: (params: { row: PatientResponseDto }) => params.row.email,
    flex: 1
  },
  {
    field: 'activationState',
    headerName: '認証の状態',
    flex: 1
  },
  {
    field: 'started',
    headerName: '撮影を開始した件数',
    type: 'number',
    valueGetter: (params: { row: PatientResponseDto }) => params.row.videos.length,
  },
  {
    field: 'uploaded',
    headerName: '撮影を完了した件数',
    type: 'number',
    valueGetter: (params: { row: PatientResponseDto }) => params.row.videos.filter((it) => it.score).length,
  },
  {
    field: 'analyzed',
    headerName: '解析が完了した件数',
    type: 'number',
    valueGetter: (params: { row: PatientResponseDto }) => params.row.videos.filter((it) => it.analyzedVideoForAdminPath).length,
  },
];

export default PatientsColumns;
