import { Button, ButtonGroup } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { InvitationResponseDto } from 'src/dto/Invitation.response.dto';

const InvitationsColumn: (copyTextToClipboard: (text: string) => void) => GridColDef[] = (copyTextToClipboard) => [
  {
    field: 'actions',
    headerName: '操作',
    flex: 1,
    renderCell: (params: { row: InvitationResponseDto }) => (
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button
          onClick={() => {
            // const text = `${process.env.REACT_APP_USER_SCREEN_URL}/registrations/${params.row.token}`;
            const text = `https://user.tokiomarine.exawizards.ai/registrations/${params.row.token}`;
            copyTextToClipboard(text);
          }}
        >
          コピー
        </Button>
      </ButtonGroup>
    ),
  }, {
    field: 'token',
    flex: 1,
    headerName: '招待トークン',
  },
  {
    field: 'status',
    headerName: '状態',
  },
];

export default InvitationsColumn;
