import { useMutation } from 'react-query';
import { PasswordRequestDto } from 'src/dto/Password.request.dto';

const apiEndpoint = [
  'https://tokiomarine-api.staging.exawizards.ai',
  '/api/v1/password'
].filter((maybe) => maybe).join('');

export const useMutatePassword = () => ({
  patch: useMutation(
    ({ dto, token }: {
      token: string;
      dto: PasswordRequestDto;
    }) => fetch(`${apiEndpoint}/${token}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(dto),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
  )
});
