import { useSnackbar } from 'notistack';
import { FC, memo, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAdminsQuery, useMutateAdmin } from 'src/hooks/useAdmin.query';
import { Props as PresenterProps } from 'src/presenters/Admins.presenter';

type Props = {
  Presenter: FC<PresenterProps>;
}

const AdminsComponent: FC<Props> = memo((
  {
    Presenter
  }
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isError } = useAdminsQuery();
  const { destroy } = useMutateAdmin();

  const admins = data || [];

  const [loading, setLoading] = useState<boolean>(false);

  const updateLoading = (bool: boolean) => {
    setLoading(bool);
  };

  const [newAdminDialogIsOpen, setNewAdminDialogIsOpen] = useState<boolean>(false);
  const openNewAdminDialog = () => {
    setNewAdminDialogIsOpen(true);
  };
  const closeNewAdminDialog = () => {
    setNewAdminDialogIsOpen(false);
  };

  const [selectedAdminIds, setSelectedAdminIds] = useState<number[]>([]);
  const updateSelectedAdminIds = (ids: number[]) => {
    setSelectedAdminIds(ids);
  };

  const navigateTo = () => {
    navigate('/patients');
  };

  const deleteButtonOnClick = () => {
    if (isLoading) return;
    if (isError) return;
    if (!selectedAdminIds.length) return;
    if (selectedAdminIds.length === admins.length) {
      alert('管理者は、最低1人以上必要です。');
      return;
    }

    const confirm = window.confirm('本当に削除しますか？');
    if (!confirm) return;

    setLoading(true);

    const promises = selectedAdminIds.map((id) => destroy.mutateAsync({ id }));

    Promise.all(promises).then((it) => {
      enqueueSnackbar(`${selectedAdminIds.length.toLocaleString()}件を削除しました`, {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      });
    }).catch(() => {
      enqueueSnackbar('エラーが発生しました', {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      });
      queryClient.clear();
    }).finally(() => {
      queryClient.invalidateQueries(['admins']).finally(() => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  if (isError) {
    navigate('/');
    return null;
  }

  return (
    <Presenter
      admins={admins}
      updateSelectedAdminIds={updateSelectedAdminIds}
      loading={loading}
      newAdminDialogIsOpen={newAdminDialogIsOpen}
      openNewAdminDialog={openNewAdminDialog}
      closeNewAdminDialog={closeNewAdminDialog}
      updateLoading={updateLoading}
      deleteButtonOnClick={deleteButtonOnClick}
      navigateTo={navigateTo}
    />
  );
});

export default AdminsComponent;
