import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useMutateAdmin } from 'src/hooks/useAdmin.query';

type Props = {
  loading: boolean;
  closeDialog: () => void;
  updateLoading: (bool: boolean) => void;
}

const AdminForm: FC<Props> = memo((
  {
    loading,
    closeDialog,
    updateLoading,
  }
) => {
  const emailReg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,32}$/;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<{
    field: string;
    message: string;
  }[]>([]);

  useEffect(() => {
    const emailError = emailReg.test(email) ? null : {
      field: 'email',
      message: 'メールアドレスをご確認ください'
    };
    const passwordError = passwordReg.test(password) ? null : {
      field: 'password',
      message: 'パスワードをご確認ください'
    };

    setErrors(
      [
        emailError,
        passwordError
      ].filter((maybe) => maybe)
    );
  }, [email, password]);

  const { post } = useMutateAdmin();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const save = () => {
    if (errors.length > 0) return;

    const dto = {
      email,
      password
    };

    post.mutate(
      dto,
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries('admins');
          enqueueSnackbar('追加しました', {
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
          });
          closeDialog();
        },
        onError: () => {
          enqueueSnackbar('エラーが発生しました、入力内容をご確認ください。', {
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
          });
          queryClient.clear();
        },
        onSettled: async () => {
          await queryClient.invalidateQueries(['admins']);
          updateLoading(false);
        }
      }
    );
  };

  return (
    <>
      <DialogTitle>
        新規追加
      </DialogTitle>
      <DialogContent>
        <Typography>
          パスワードは、必ずお手元で管理してください。
        </Typography>
        <Stack
          spacing={1}
        >
          <Typography>
            管理者でご利用いただいているメールアドレスは、お客様アプリではご利用いただけません。
          </Typography>
          <TextField
            label="メールアドレス"
            type="email"
            size="small"
            variant="standard"
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            value={email}
            error={!!errors.find((it) => it.field === 'email')}
          />
          <TextField
            label="パスワード"
            type="password"
            size="small"
            variant="standard"
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setPassword(e.target.value);
            }}
            value={password}
            helperText="8～32文字の半角英数字及び記号。英字・数字の二種を必ず使用必要がります。"
            error={!!errors.find((it) => it.field === 'password')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          startIcon={<SaveIcon />}
          onClick={save}
          disabled={!!errors.length}
        >
          保存する
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default AdminForm;
