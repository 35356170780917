import { useQuery } from 'react-query';
import { InvitationResponseDto } from 'src/dto/Invitation.response.dto';

const apiEndpoint = [
  'https://tokiomarine-api.staging.exawizards.ai',
  '/api/v1/admin/invitations'
].filter((maybe) => maybe).join('');

const getRecords: () => () => Promise<InvitationResponseDto[]> = () => async () => {
  const requestPath = apiEndpoint;

  const data: Response = await fetch(requestPath, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return data.json();
};

export const useInvitationsRequest = () => useQuery(
  ['invitations'],
  getRecords(),
  {
    staleTime: Infinity
  }
);
