import { GridColDef } from '@mui/x-data-grid';
import { AdminResponseDto } from 'src/dto/Admin.response.dto';

const AdminColumns: () => GridColDef[] = () => [
  { field: 'id', headerName: 'ID' },
  {
    field: 'email',
    headerName: 'メールアドレス',
    valueGetter: (params: { row: AdminResponseDto }) => params.row.email,
    flex: 1
  },
  {
    field: 'activationState',
    headerName: '認証の状態',
    flex: 1
  },
];

export default AdminColumns;
