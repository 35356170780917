import { useSnackbar } from 'notistack';
import { FC, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePatientQuery } from 'src/hooks/usePatients.query';
import { useVideosQuery } from 'src/hooks/useVideos.query';
import { Props as PresenterProps } from 'src/presenters/Patient.presenter';

import { VideoResponseDto } from '../dto/Video.response.dto';

type Props = {
  id: number;
  Presenter: FC<PresenterProps>
}

const PatientComponent: FC<Props> = memo((
  {
    id,
    Presenter
  }
) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data: patientData, isLoading: patientIsLoading, isError: patientIsError } = usePatientQuery(id);
  const { data: videoData, isLoading: videosIsLoading, isError: videosIsError } = useVideosQuery(id);

  const [videos, setVideos] = useState<VideoResponseDto[]>([]);

  const navigateToIndex = () => {
    navigate('/patients');
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [displayVideos, setDisplayVideos] = useState<VideoResponseDto[]>([]);
  const updateDisplayVideos = (dtos: VideoResponseDto[]) => {
    setDisplayVideos(dtos);
  };
  const resetDisplayVideos = () => {
    setDisplayVideos([]);
  };
  const [selectedVideoIds, setSelectedVideoIds] = useState<number[]>([]);
  const updateSelectedVideoIds = (ids: number[]) => {
    const reversed = [...ids].reverse();
    const sliced = [...reversed].slice(0, 2);

    setSelectedVideoIds(
      sliced
    );
  };

  const compareVideos = () => {
    if (selectedVideoIds.length !== 2) {
      enqueueSnackbar('動画を2件以上選択してください');
      return;
    }

    const selectedVideos = videos
      .filter((it) => selectedVideoIds.includes(it.id));

    if (selectedVideos.length !== 2) {
      enqueueSnackbar('動画を2件以上選択してください');
      return;
    }

    setDisplayVideos(selectedVideos);
  };

  const [series, setSeries] = useState<{
    data: number[],
    label: string,
  }[]>([]);

  useEffect(() => {
    if (!videoData) {
      setVideos([]);
      return;
    }

    const reversedData = [...videoData].reverse();

    setVideos(reversedData);
  }, [videoData]);

  useEffect(() => {
    if (!videos || !videos.length) {
      setSeries([]);
      return;
    }

    const neckPainData = videos
      .map((it) => it.neckPain ?? 0);

    const shoulderPainData = videos
      .map((it) => it.shoulderPain ?? 0);

    const dailyLifeDegreeData = videos
      .map((it) => it.dailyLifeDegree ?? 0);

    const maxPitchDegFrontData = videos
      .map((it) => it.score?.pitch?.front ?? 0);
    const maxPitchDegBackData = videos
      .map((it) => it.score?.pitch?.back ?? 0);

    const maxYawDegLeftData = videos
      .map((it) => it.score?.yaw?.left ?? 0);
    const maxYawDegRightData = videos
      .map((it) => it.score?.yaw?.right ?? 0);

    const maxRollDegLeftData = videos
      .map((it) => it.score?.roll.left ?? 0);
    const maxRollDegRightData = videos
      .map((it) => it.score?.roll.right ?? 0);

    setSeries(
      [
        {
          label: '首の痛み',
          data: neckPainData
        },
        {
          label: '肩の痛み',
          data: shoulderPainData
        },
        {
          label: '生活への支障',
          data: dailyLifeDegreeData
        },
        {
          label: '屈曲／伸展(前）',
          data: maxPitchDegFrontData
        },
        {
          label: '屈曲／伸展(後)',
          data: maxPitchDegBackData
        },
        {
          label: '回旋(左)',
          data: maxYawDegLeftData
        },
        {
          label: '回旋(右)',
          data: maxYawDegRightData
        },
        {
          label: '屈側(左)',
          data: maxRollDegLeftData
        },
        {
          label: '屈側(右)',
          data: maxRollDegRightData
        },
      ]
    );
  }, [videos]);

  useEffect(() => {
    setLoading(
      patientIsLoading || videosIsLoading
    );
  }, [patientIsLoading, videosIsLoading]);

  if (patientIsError || videosIsError) {
    navigate('/');
    return null;
  }

  return (
    <Presenter
      videos={videos}
      loading={loading}
      navigateToIndex={navigateToIndex}
      patientRequestDto={patientData}
      displayVideos={displayVideos}
      updateDisplayVideos={updateDisplayVideos}
      resetDisplayVideos={resetDisplayVideos}
      series={series}
      compareVideos={compareVideos}
      updateSelectedVideoIds={updateSelectedVideoIds}
    />
  );
});

export default PatientComponent;
