import React, { FC, memo } from 'react';

import PatientsComponent from '../components/Patients.component';
import PatientsPresenter from '../presenters/Patients.presenter';

const PatientsPage: FC = memo(() => (
  <PatientsComponent
    Presenter={PatientsPresenter}
  />
));

export default PatientsPage;
