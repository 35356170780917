import { useSnackbar } from 'notistack';
import { FC, memo, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Props as PresenterProps } from 'src/presenters/Password.presenter';

import { useMutatePassword } from '../hooks/usePassword.query';

type Props = {
  token: string;
  Presenter: FC<PresenterProps>;
}

const PasswordComponent: FC<Props> = memo((
  {
    token,
    Presenter,
  }
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { patch } = useMutatePassword();
  const { enqueueSnackbar } = useSnackbar();

  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [canRequest, setCanRequest] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const updatePassword = (val: string) => {
    setPassword(val);
  };

  const updatePasswordConfirmation = (val: string) => {
    setPasswordConfirmation(val);
  };

  const submitButtonOnClick = () => {
    if (!canRequest) return;

    const requestBody = {
      token,
      dto: {
        password
      }
    };

    setLoading(true);

    patch.mutate(requestBody, {
      onSuccess: () => {
        enqueueSnackbar('パスワードが登録されました', {
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        });
        navigate('/');
      },
      onError: () => {
        enqueueSnackbar('エラーが発生しました', {
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        });
      },
      onSettled: () => {
        queryClient.clear();
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!password) {
      setCanRequest(false);
      return;
    }

    if (!passwordConfirmation) {
      setCanRequest(false);
      return;
    }

    setCanRequest(password === passwordConfirmation);
  });

  return (
    <Presenter
      password={password}
      passwordConfirmation={passwordConfirmation}
      updatePassword={updatePassword}
      updatePasswordConfirmation={updatePasswordConfirmation}
      canRequest={canRequest}
      submitButtonOnClick={submitButtonOnClick}
    />
  );
});

export default PasswordComponent;
