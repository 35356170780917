import { isAxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SessionRequestDto } from 'src/dto/Session.request.dto';
import { useMutationSession } from 'src/hooks/useSession.query';
import { Props as PresenterProps } from 'src/presenters/Login.presenter';

type Props = {
  Presenter: FC<PresenterProps>;
};

const LoginComponent: FC<Props> = memo(({ Presenter }) => {
  const navigate = useNavigate();
  const { post } = useMutationSession();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [canRequest, setCanRequest] = useState<boolean>(false);

  const updateEmail = useCallback((val: string) => {
    setEmail(val);
  }, []);
  const updatePassword = useCallback((val: string) => {
    setPassword(val);
  }, []);
  const submitButtonOnClick: () => void = () => {
    if (!canRequest) return;

    const dto: SessionRequestDto = {
      email,
      password,
    };

    post.mutate(dto, {
      onSuccess: () => {
        navigate('/patients');
      },
      onError: (error) => {
        if (isAxiosError(error) && error.response.status === 403) {
          const token = error?.response?.data as string;
          enqueueSnackbar('初回ログイン時は、パスワードの変更が必要です。', {
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
          });
          navigate(`/activation/${token}`);
          return;
        }

        enqueueSnackbar('入力内容をご確認ください', {
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        });
        queryClient.clear();
      },
    });
  };

  useEffect(() => {
    setCanRequest([email, password].every((maybe) => !!maybe));
  }, [email, password]);

  return (
    <Presenter
      email={email}
      updateEmail={updateEmail}
      password={password}
      updatePassword={updatePassword}
      canRequest={canRequest}
      submitButtonOnClick={submitButtonOnClick}
    />
  );
});

export default LoginComponent;
