import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { FC, memo } from 'react';

type Props = {
  addInvitation: () => void;
  deleteSelectedInvitations: () => void;
  isLoading: boolean;
}

const InvitationsToolbarPresenter: FC<Props> = memo((
  {
    addInvitation,
    deleteSelectedInvitations,
    isLoading
  }
) => (
  <GridToolbarContainer
    sx={{
      width: '100%'
    }}
  >
    <Stack
      direction="row"
      gap={1}
    >
      <LoadingButton
        loading={isLoading}
        onClick={addInvitation}
        startIcon={<AddCircleIcon />}
      >
        新規追加
      </LoadingButton>
      <LoadingButton
        loading={isLoading}
        onClick={deleteSelectedInvitations}
        startIcon={<DeleteForeverIcon />}
      >
        削除する
      </LoadingButton>
    </Stack>
  </GridToolbarContainer>
));

export default InvitationsToolbarPresenter;
