import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { AdminRequestDto } from 'src/dto/Admin.request.dto';
import { AdminResponseDto } from 'src/dto/Admin.response.dto';

const apiEndpoint = [
  'https://tokiomarine-api.staging.exawizards.ai',
  '/api/v1/admin/users'
].filter((maybe) => maybe).join('');

const getRecords: (queryClient: QueryClient) => () => Promise<AdminResponseDto[]> = (queryClient: QueryClient) => async () => {
  const data: Response = await fetch(apiEndpoint, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return data.json();
};

export const useAdminsQuery = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ['admins'],
    getRecords(queryClient),
    {
      staleTime: Infinity
    }
  );
};

export const useMutateAdmin = () => ({
  post: useMutation(
    (dto: AdminRequestDto) => fetch(apiEndpoint, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(dto),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
  ),
  destroy: useMutation(
    ({ id }: { id: number; }) => fetch(`${apiEndpoint}/${id}`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
  )
});
