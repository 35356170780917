import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import PasswordComponent from 'src/components/Password.component';
import PasswordPresenter from 'src/presenters/Password.presenter';

const PasswordPage: FC = memo(() => {
  const params = useParams();

  const { token } = params;

  return (
    <PasswordComponent token={token} Presenter={PasswordPresenter} />
  );
});

export default PasswordPage;
