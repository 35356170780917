import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, memo } from 'react';

export type Props = {
  email: string;
  updateEmail: (email: string) => void;
  password: string;
  updatePassword: (password: string) => void;
  canRequest: boolean;
  submitButtonOnClick: () => void;
};

const LoginPresenter: FC<Props> = memo(
  ({
    email,
    updateEmail,
    password,
    updatePassword,
    canRequest,
    submitButtonOnClick,
  }) => (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      height="100vh"
    >
      <Card
        sx={{
          width: 360,
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Typography gutterBottom>ログイン</Typography>
            <TextField
              value={email}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                updateEmail(event.target.value);
              }}
              type="email"
              fullWidth
              variant="standard"
              label="メールアドレス"
              size="small"
            />
            <TextField
              value={password}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                updatePassword(event.target.value);
              }}
              type="password"
              fullWidth
              variant="standard"
              label="パスワード"
              size="small"
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            onClick={submitButtonOnClick}
            disabled={!canRequest}
            fullWidth
            variant="outlined"
          >
            ログイン
          </Button>
        </CardActions>
      </Card>
    </Stack>
  ),
);

export default LoginPresenter;
