import { useSnackbar } from 'notistack';
import { FC, memo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { InvitationResponseDto } from 'src/dto/Invitation.response.dto';
import { useInvitationMutation } from 'src/hooks/useInvitation.mutation';
import { useInvitationsRequest } from 'src/hooks/useInvitations.request';
import { Props as PresenterProps } from 'src/presenters/Invitations.presenter';

type Props = {
  Presenter: FC<PresenterProps>
}

const InvitationsComponent: FC<Props> = memo((
  {
    Presenter,
  }
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = useInvitationsRequest();
  const { post, destroy } = useInvitationMutation();

  const invitations: InvitationResponseDto[] = data || [];
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedInvitationIds, setSelectedInvitationIds] = useState<number[]>([]);
  const updateSelectedInvitationIds = (ids: number[]) => {
    setSelectedInvitationIds(ids);
  };

  const navigateToPatients = () => {
    navigate('/patients');
  };

  const copyTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar('コピーしました');
    }).catch((e) => {
      throw e;
    });
  };

  const deleteSelectedInvitations = () => {
    if (isLoading) return;
    if (!selectedInvitationIds.length) return;

    setLoading(true);

    const promises = selectedInvitationIds.map((id) => destroy.mutateAsync({ id }));

    Promise.all(promises).then((it) => {
      enqueueSnackbar(`${selectedInvitationIds.length.toLocaleString()}件を削除しました`, {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      });
    }).catch(() => {
      enqueueSnackbar('エラーが発生しました', {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      });
    }).finally(() => {
      queryClient.clear();
      setLoading(false);
    });
  };

  const addInvitation = () => {
    setLoading(true);

    post.mutate(undefined, {
      onSuccess: () => {
        enqueueSnackbar('追加しました', {
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        });
      },
      onError: () => {
        enqueueSnackbar('エラーが発生しました', {
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        });
        queryClient.clear();
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(['invitations']);
        setLoading(false);
      }
    });
  };

  return (
    <Presenter
      invitations={invitations}
      isLoading={loading}
      addInvitation={addInvitation}
      deleteSelectedInvitations={deleteSelectedInvitations}
      navigateToPatients={navigateToPatients}
      updateSelectedInvitationIds={updateSelectedInvitationIds}
      copyTextToClipboard={copyTextToClipboard}
    />
  );
});

export default InvitationsComponent;
