import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';

import PatientComponent from '../components/Patient.component';
import PatientPresenter from '../presenters/Patient.presenter';

const PatientPage: FC = memo(() => {
  const params = useParams();

  const id = Number(params.id);

  return (
    <PatientComponent id={id} Presenter={PatientPresenter} />
  );
});

export default PatientPage;
