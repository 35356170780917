import { useSnackbar } from 'notistack';
import { FC, memo, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useMutatePatient, usePatientsQuery } from 'src/hooks/usePatients.query';
import { useMutationSession } from 'src/hooks/useSession.query';
import { Props as PresenterProps } from 'src/presenters/Patients.presenter';

type Props = {
  Presenter: FC<PresenterProps>;
}

const PatientsComponent: FC<Props> = memo((
  {
    Presenter
  }
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { destroy: logout } = useMutationSession();
  const { enqueueSnackbar } = useSnackbar();
  const { data: patientData, isLoading: patientsIsLoading, isError: patientIsError } = usePatientsQuery();
  const { destroy } = useMutatePatient();

  const patients = patientData || [];

  const [loading, setLoading] = useState<boolean>(false);

  const updateLoading = (bool: boolean) => {
    setLoading(bool);
  };

  const [newPatientDialogIsOpen, setNewPatientDialogIsOpen] = useState<boolean>(false);
  const openNewPatientDialog = () => {
    setNewPatientDialogIsOpen(true);
  };
  const closeNewPatientDialog = () => {
    setNewPatientDialogIsOpen(false);
  };

  const [selectedPatientIds, setSelectedPatientIds] = useState<number[]>([]);
  const updateSelectedPatientIds = (ids: number[]) => {
    setSelectedPatientIds(ids);
  };

  const [newQuestionDialogIsOpen, setNewQuestionDialogIsOpen] = useState<boolean>(false);
  const closeNewQuestionDialog = () => {
    setNewQuestionDialogIsOpen(false);
  };

  const navigateTo = (id: number) => {
    navigate(`/patients/${id}`);
  };

  const navigateToAdmins = () => {
    navigate('/admins');
  };

  const navigateToInvitations = () => {
    navigate('/invitations');
  };

  const deleteButtonOnClick = () => {
    if (patientsIsLoading) return;
    if (patientIsError) return;
    if (!selectedPatientIds.length) return;

    setLoading(true);

    const promises = selectedPatientIds.map((id) => destroy.mutateAsync({ id }));

    Promise.all(promises).then((it) => {
      enqueueSnackbar(`${selectedPatientIds.length.toLocaleString()}件を削除しました`, {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      });
    }).catch(() => {
      enqueueSnackbar('エラーが発生しました', {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      });
    }).finally(() => {
      queryClient.clear();
      setLoading(false);
    });
  };

  const logoutButtonOnClick = () => {
    if (patientsIsLoading) return;
    if (patientIsError) return;

    const confirm = window.confirm('ログアウトしますか？');

    if (!confirm) return;

    logout.mutate(undefined, {
      onSuccess: () => {
        navigate('/');
      },
      onError: () => {
        enqueueSnackbar('エラーが発生しました', {
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        });
      },
      onSettled: () => {
        queryClient.clear();
      }
    });
  };

  useEffect(() => {
    setLoading(patientsIsLoading);
  }, [patientsIsLoading]);

  if (patientIsError) {
    navigate('/');
    return null;
  }

  const copyTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar('コピーしました');
    }).catch((e) => {
      throw e;
    });
  };

  return (
    <Presenter
      patients={patients}
      updateSelectedPatientIds={updateSelectedPatientIds}
      loading={loading}
      navigateTo={navigateTo}
      logoutButtonOnClick={logoutButtonOnClick}
      newPatientDialogIsOpen={newPatientDialogIsOpen}
      openNewPatientDialog={openNewPatientDialog}
      closeNewPatientDialog={closeNewPatientDialog}
      newQuestionDialogIsOpen={newQuestionDialogIsOpen}
      closeNewQuestionDialog={closeNewQuestionDialog}
      updateLoading={updateLoading}
      deleteButtonOnClick={deleteButtonOnClick}
      navigateToAdmins={navigateToAdmins}
      copyTextToClipboard={copyTextToClipboard}
      navigateToInvitations={navigateToInvitations}
    />
  );
});

export default PatientsComponent;
