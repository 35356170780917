import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns-tz';
import { FC, memo } from 'react';
import ReactPlayer from 'react-player';

type Props = {
  neckPain: number;
  shoulderPain: number;
  dailyLifeDegree: number;
  createdAt: string;
  url: string;
  score?: {
    pitch: {
      front: number;
      back: number;
    },
    yaw: {
      left: number;
      right: number;
    },
    roll: {
      left: number;
      right: number;
    },
  };
}

const VideoPresenter: FC<Props> = memo((
  {
    url,
    neckPain,
    shoulderPain,
    dailyLifeDegree,
    createdAt,
    score,
  }
) => {
  const createdDate = format(
    new Date(createdAt),
    'yyyy-MM-dd',
    {
      timeZone: 'Asia/Tokyo'
    }
  );

  return (
    <Stack>
      <Stack>
        <Typography>
          {createdDate}
        </Typography>
        <Stack
          direction="row"
          gap={1}
        >
          <Typography>
            自己評価：
            {
              [
                `首の痛み${neckPain}`,
                `肩の痛み${shoulderPain}`,
                `生活への支障${dailyLifeDegree}`
              ].join(' ')
            }
          </Typography>
        </Stack>
        {
          score && (
            <Stack
              direction="row"
              gap={1}
            >
              <Typography>
                解析結果：
                {
                  [
                    `屈曲${score?.pitch.front}`,
                    `伸展${score?.pitch.back}`,
                    `回旋左${score?.yaw.left}`,
                    `回旋右${score?.yaw.right}`,
                    `屈側左${score?.roll.left}`,
                    `屈側右${score?.roll.right}`,
                  ].join(' ')
                }
              </Typography>
              <Typography>
                ※自己評価は10段階評価、解析結果は5段階評価
              </Typography>
            </Stack>
          )
        }
      </Stack>
      <ReactPlayer
        width="100%"
        height="100%"
        url={`https://tokiomarine-api.staging.exawizards.ai${url}`}
        controls
        playing
      />
    </Stack>
  );
});

export default VideoPresenter;
