import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { DataGrid, GridRowId, jaJP } from '@mui/x-data-grid';
import { FC, memo } from 'react';
import InvitationsColumn from 'src/columns/Invitations.column';
import { InvitationResponseDto } from 'src/dto/Invitation.response.dto';

import InvitationsToolbarPresenter from './Invitations.toolbar.presenter';

export type Props = {
  invitations: InvitationResponseDto[];
  isLoading: boolean;
  addInvitation: () => void;
  deleteSelectedInvitations: () => void;
  navigateToPatients: () => void;
  copyTextToClipboard: (text: string) => void;
  updateSelectedInvitationIds: (ids: number[]) => void;
}

const InvitationsPresenter: FC<Props> = memo((
  {
    invitations,
    addInvitation,
    deleteSelectedInvitations,
    isLoading,
    navigateToPatients,
    copyTextToClipboard,
    updateSelectedInvitationIds,
  }
) => (
  <Stack
    p={4}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      mb={2}
    >
      <Stack
        direction="row"
        alignItems="center"
      >
        <IconButton
          onClick={navigateToPatients}
          disabled={isLoading}
        >
          <ArrowBackIcon />
        </IconButton>
        <Stack>
          <Typography>
            招待一覧
          </Typography>
        </Stack>
      </Stack>
    </Stack>
    <DataGrid
      columns={InvitationsColumn(copyTextToClipboard)}
      rows={invitations}
      checkboxSelection
      loading={isLoading}
      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      components={{
        LoadingOverlay: LinearProgress,
        Toolbar: InvitationsToolbarPresenter
      }}
      componentsProps={{
        toolbar: {
          addInvitation,
          isLoading,
          deleteSelectedInvitations
        }
      }}
      onRowSelectionModelChange={(gridRowIds: GridRowId[]) => {
        updateSelectedInvitationIds(
          gridRowIds
            .map((it) => Number(it))
        );
      }}
    />
  </Stack>
));

export default InvitationsPresenter;
