import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { QuestionRequestDto } from 'src/dto/Question.request.dto';
import { useMutateQuestion } from 'src/hooks/useQuestions.query';
import questionSchema from 'src/schemas/question.schema';

type Props = {
  loading: boolean;
  updateLoading: (bool: boolean) => void;
  closeDialog: () => void;
}
const QuestionForm: FC<Props> = memo((
  {
    loading,
    updateLoading,
    closeDialog,
  }
) => {
  const { post } = useMutateQuestion();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { control, formState: { errors }, handleSubmit, register } = useForm<QuestionRequestDto>({
    resolver: yupResolver(questionSchema),
    defaultValues: {
      title: '',
      answers: [
        {
          value: ''
        }
      ]
    }
  });

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'answers'
  });

  const onSubmit: SubmitHandler<QuestionRequestDto> = (dto: QuestionRequestDto) => {
    post.mutate(
      dto,
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries('questions');
          enqueueSnackbar('追加しました');
          closeDialog();
        },
        onError: (e: AxiosError<string[]>) => {
          if (e.status === 400) {
            e.response.data.map((it) => {
              enqueueSnackbar(it);
              return it;
            });
          }
          queryClient.clear();
        },
        onSettled: () => {
          updateLoading(false);
        }
      }
    );
  };

  return (
    <>
      <DialogTitle>
        新規質問追加
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
        >
          <TextField
            {...register('title')}
            error={'title' in errors}
            helperText={errors.title?.message}
            label="質問タイトル"
            size="small"
            variant="standard"
            fullWidth
          />
          <Stack
            spacing={1}
          >
            {
              fields.map((f, idx) => (
                <Stack
                  key={
                    [
                      'answers',
                      idx,
                      f.id,
                    ].join('-')
                  }
                  direction="row"
                  gap={1}
                >
                  <TextField
                    label={`回答${idx + 1}`}
                    {...register(`answers.${idx}.value` as const, {
                      required: true,
                    })}
                    error={!!errors?.answers?.[idx]?.value.message}
                    helperText={errors?.answers?.[idx]?.value.message}
                    size="small"
                    variant="standard"
                    fullWidth
                  />
                  {
                    (idx > 0) && (
                      <IconButton
                        disabled={loading}
                        onClick={() => {
                          remove(idx);
                        }}
                        color="error"
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    )
                  }
                </Stack>
              ))
            }
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          onClick={() => {
            append({
              value: ''
            });
          }}
          startIcon={<AddCircleIcon />}
        >
          回答を追加する
        </LoadingButton>
        <LoadingButton
          loading={loading}
          startIcon={<SaveIcon />}
          onClick={handleSubmit(onSubmit)}
        >
          保存する
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default QuestionForm;
