import { useMutation } from 'react-query';

const apiEndpoint = [
  'https://tokiomarine-api.staging.exawizards.ai',
  '/api/v1/admin/invitations'
].filter((maybe) => maybe).join('');

export const useInvitationMutation = () => ({
  post: useMutation(
    () => fetch(apiEndpoint, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
  ),
  destroy: useMutation(
    ({ id }: {id: number}) => fetch(`${apiEndpoint}/${id}`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
  ),
});
