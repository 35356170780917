import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { PatientRequestDto } from 'src/dto/Patient.request.dto';
import { useMutatePatient } from 'src/hooks/usePatients.query';
import patientSchema from 'src/schemas/patient.schema';

type Props = {
  loading: boolean;
  closeDialog: () => void;
  updateLoading: (bool: boolean) => void;
  defaultDto?: PatientRequestDto;
  id?: number;
}

const PatientForm: FC<Props> = memo((
  {
    loading,
    closeDialog,
    updateLoading,
    defaultDto,
    id,
  }
) => {
  const { formState: { errors }, handleSubmit, register, reset } = useForm<PatientRequestDto>({
    resolver: yupResolver(patientSchema),
  });
  const { post, patch } = useMutatePatient();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const isEdit = [defaultDto, id].every((maybe) => maybe);

  const update = (dto: PatientRequestDto) => {
    patch.mutate(
      {
        dto,
        id
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries('patients');
          enqueueSnackbar('更新しました');
          closeDialog();
        },
        onError: () => {
          queryClient.clear();
        },
        onSettled: () => {
          updateLoading(false);
        }
      }
    );
  };

  const save = (dto: PatientRequestDto) => {
    post.mutate(
      dto,
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries('patients');
          enqueueSnackbar('追加しました', {
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
          });
          closeDialog();
        },
        onError: (err: AxiosError<string[]>) => {
          if (err.response.status === 400) {
            err.response.data.map((str) => {
              enqueueSnackbar(str, {
                anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
              });
              return str;
            });
          }
        },
        onSettled: () => {
          queryClient.clear();
          updateLoading(false);
        }
      }
    );
  };

  const onSubmit: SubmitHandler<PatientRequestDto> = (dto: PatientRequestDto) => {
    if (isEdit) {
      update(dto);
      return;
    }

    save(dto);
  };

  useEffect(() => {
    if (!defaultDto) return;

    reset({
      email: defaultDto.email,
    });
  }, [defaultDto]);

  return (
    <>
      <DialogTitle>
        {
          isEdit ? '登録情報修正' : '新規顧客追加'
        }
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={1}
        >
          <TextField
            {...register('email')}
            error={'email' in errors}
            helperText={errors.email?.message}
            label="メールアドレス"
            type="email"
            size="small"
            variant="standard"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          startIcon={<SaveIcon />}
          onClick={handleSubmit(onSubmit)}
        >
          保存する
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default PatientForm;
