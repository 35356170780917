import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { FC, memo } from 'react';

type Props = {
  compareVideos: () => void;
  isLoading: boolean;
}

const PatientToolbarPresenter: FC<Props> = memo((
  {
    compareVideos,
    isLoading
  }
) => (
  <GridToolbarContainer
    sx={{
        width: '100%'
      }}
  >
    <Stack
      direction="row"
      gap={1}
    >
      <LoadingButton
        loading={isLoading}
        onClick={compareVideos}
        startIcon={<CompareArrowsRoundedIcon />}
      >
        動画を比較する
      </LoadingButton>
    </Stack>
  </GridToolbarContainer>
  ));

export default PatientToolbarPresenter;
