import { useMutation } from 'react-query';

import { SessionRequestDto } from '../dto/Session.request.dto';

const requestPath = [
  'https://tokiomarine-api.staging.exawizards.ai',
  '/api/v1/admin/session'
].filter((maybe) => maybe).join('');

export const useMutationSession = () => ({
  post: useMutation((dto: SessionRequestDto) => fetch(requestPath, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(dto),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })),
  destroy: useMutation(() => fetch(requestPath, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })),
});
