import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Dialog, DialogContent, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { ja } from 'date-fns/locale';
import { format } from 'date-fns-tz';
import { FC, memo } from 'react';
import PatientColumns from 'src/columns/Patient.columns';
import { PatientRequestDto } from 'src/dto/Patient.request.dto';
import { VideoResponseDto } from 'src/dto/Video.response.dto';

import PatientToolbarPresenter from './Patient.toolbar.presenter';
import VideoPresenter from './Video.presenter';

export type Props = {
  videos: VideoResponseDto[];
  navigateToIndex: () => void;
  loading: boolean;
  patientRequestDto: PatientRequestDto | undefined;
  displayVideos: VideoResponseDto[];
  updateDisplayVideos: (dtos: VideoResponseDto[]) => void;
  resetDisplayVideos: () => void;
  series: {
    data: number[],
    label: string,
  }[];
  compareVideos: () => void;
  updateSelectedVideoIds: (ids: number[]) => void;
}

const PatientPresenter: FC<Props> = memo((
  {
    videos,
    navigateToIndex,
    loading,
    patientRequestDto,
    displayVideos,
    updateDisplayVideos,
    resetDisplayVideos,
    series,
    compareVideos,
    updateSelectedVideoIds,
  }
) => (
  <Stack
    p={4}
  >
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      mb={2}
    >
      <IconButton
        onClick={navigateToIndex}
        disabled={loading}
      >
        <ArrowBackIcon />
      </IconButton>
      <Stack>
        <Typography>
          {patientRequestDto?.email}
          {' '}
          さん
        </Typography>
      </Stack>
    </Stack>
    <Stack>
      <LineChart
        height={300}
        series={series}
        xAxis={[
          {
            scaleType: 'point',
            data: videos.map((it) => format(
              new Date(it.createdAt),
              'MM月dd日(eee)',
              { locale: ja }
            ))
          }
        ]}
        {
          ...{
            legend: {
              position: {
                vertical: 'middle',
                horizontal: 'right',
              },
              direction: 'column',
              offset: {
                x: 80,
                y: 0
              }
            },
          }
        }
      />
    </Stack>
    <DataGrid
      columns={PatientColumns(videos, updateDisplayVideos)}
      rows={[...videos].reverse()}
      loading={loading}
      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      components={{
        LoadingOverlay: LinearProgress,
        Toolbar: PatientToolbarPresenter
      }}
      componentsProps={{
        toolbar: {
          compareVideos,
          isLoading: loading,
        }
      }}
      checkboxSelection
      onRowSelectionModelChange={(ids) => {
        updateSelectedVideoIds(ids as number[]);
      }}
    />
    <Dialog
      open={!!displayVideos.length}
      onClose={resetDisplayVideos}
      fullScreen
      sx={{
        mt: 8
      }}
    >
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          {displayVideos.filter((it) => it.analyzedVideoForAdminPath).map((it) => {
            if (!it.analyzedVideoForAdminPath) {
              return null;
            }
            return (
              <VideoPresenter
                key={
                  [
                    'ReactPlayer',
                    it.id
                  ].join('-')
                }
                neckPain={it.neckPain ?? 0}
                shoulderPain={it.shoulderPain ?? 0}
                dailyLifeDegree={it.dailyLifeDegree ?? 0}
                createdAt={it.createdAt}
                score={it.score}
                url={it.analyzedVideoForAdminPath}
              />
            );
          })}
        </Stack>
      </DialogContent>
    </Dialog>
  </Stack>
));

export default PatientPresenter;
