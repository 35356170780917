import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ja } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ActivationPage from 'src/pages/Activation.page';
import AdminsPage from 'src/pages/Admins.page';
import InvitationsPage from 'src/pages/Invitations.page';
import LoginPage from 'src/pages/Login.page';
import PatientPage from 'src/pages/Patient.page';
import PatientsPage from 'src/pages/Patients.page';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PasswordPage from 'src/pages/Password.page';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ja}
        >
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={<LoginPage />}
              />
              <Route
                path="patients"
                element={<PatientsPage />}
              />
              <Route
                path="patients/:id"
                element={<PatientPage />}
              />
              <Route
                path="activation/:token"
                element={<ActivationPage />}
              />
              <Route
                path="password/:token"
                element={<PasswordPage />}
              />
              <Route
                path="admins"
                element={<AdminsPage />}
              />
              <Route
                path="invitations"
                element={<InvitationsPage />}
              />
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
