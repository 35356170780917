import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Dialog, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { DataGrid, GridRowId, jaJP } from '@mui/x-data-grid';
import { FC, memo } from 'react';
import AdminColumns from 'src/columns/Admins.columns';
import { AdminResponseDto } from 'src/dto/Admin.response.dto';
import AdminForm from 'src/forms/Admin.form';

import AdminsToolbarPresenter from './Admins.toolbar.presenter';

export type Props = {
  admins: AdminResponseDto[];
  updateSelectedAdminIds: (ids: number[]) => void;
  loading: boolean;
  newAdminDialogIsOpen: boolean;
  openNewAdminDialog: () => void;
  closeNewAdminDialog: () => void;
  updateLoading: (bool: boolean) => void;
  deleteButtonOnClick: () => void;
  navigateTo: () => void;
}

const AdminsPresenter: FC<Props> = memo((
  {
    admins,
    updateLoading,
    updateSelectedAdminIds,
    newAdminDialogIsOpen,
    openNewAdminDialog,
    closeNewAdminDialog,
    deleteButtonOnClick,
    navigateTo,
    loading,
  }
) => (
  <>
    <Stack
      p={4}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        mb={2}
      >
        <IconButton
          onClick={navigateTo}
          disabled={loading}
        >
          <ArrowBackIcon />
        </IconButton>
        <Stack>
          <Typography>
            管理者一覧
          </Typography>
        </Stack>
      </Stack>
      <DataGrid
        columns={AdminColumns()}
        rows={admins}
        checkboxSelection
        loading={loading}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: AdminsToolbarPresenter
        }}
        componentsProps={{
          toolbar: {
            openNewAdminDialog,
            isLoading: loading,
            deleteButtonOnClick
          }
        }}
        onRowSelectionModelChange={(gridRowIds: GridRowId[]) => {
          updateSelectedAdminIds(
            gridRowIds
              .map((it) => Number(it))
          );
        }}
      />
    </Stack>
    <Dialog
      open={newAdminDialogIsOpen}
      onClose={closeNewAdminDialog}
      maxWidth="sm"
      fullWidth
    >
      <AdminForm
        loading={loading}
        closeDialog={closeNewAdminDialog}
        updateLoading={updateLoading}
      />
    </Dialog>
  </>
));

export default AdminsPresenter;
