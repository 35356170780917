import axios from 'axios';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';

import { PatientRequestDto } from '../dto/Patient.request.dto';
import { PatientResponseDto } from '../dto/Patient.response.dto';

const apiEndpoint = [
  'https://tokiomarine-api.staging.exawizards.ai',
  '/api/v1/admin/patients'
].filter((maybe) => maybe).join('');

const getRecords: (queryClient: QueryClient) => () => Promise<PatientResponseDto[]> = (queryClient: QueryClient) => async () => {
  const data: Response = await fetch(apiEndpoint, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return data.json();
};

const getRecord: (id: number) => () => Promise<PatientResponseDto> = (id: number) => async () => {
  const requestPath = `${apiEndpoint}/${id}`;

  const data: Response = await fetch(requestPath, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return data.json();
};

export const usePatientsQuery = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ['patients'],
    getRecords(queryClient),
    {
      staleTime: Infinity
    }
  );
};

export const usePatientQuery = (id: number) => useQuery(
  ['patients', { id }],
  getRecord(id),
  {
    staleTime: Infinity,
    enabled: Boolean(id)
  }
);

export const useMutatePatient = () => ({
  post: useMutation(
    (dto: PatientRequestDto) => axios.post(apiEndpoint, dto)
  ),
  patch: useMutation(
    ({ dto, id }: {
      id: number;
      dto: PatientRequestDto;
    }) => axios.patch(`${apiEndpoint}/${id}`, dto)
  ),
  destroy: useMutation(
    ({ id }: { id: number; }) => fetch(`${apiEndpoint}/${id}`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
  )
});
