import React, { FC, memo } from 'react';
import Component from 'src/components/Invitations.component';
import Presenter from 'src/presenters/Invitations.presenter';

const InvitationsPage: FC = memo(() => (
  <Component
    Presenter={Presenter}
  />
));

export default InvitationsPage;
