import { Button, Card, CardActions, CardContent, Stack, TextField, Typography } from '@mui/material';
import React, { FC, memo } from 'react';

export type Props = {
  password: string;
  updatePassword: (val: string) => void;
  passwordConfirmation: string;
  updatePasswordConfirmation: (val: string) => void;
  submitButtonOnClick: () => void;
  canRequest: boolean;
}

const PasswordPresenter: FC<Props> = memo((
  {
    password,
    passwordConfirmation,
    updatePassword,
    updatePasswordConfirmation,
    submitButtonOnClick,
    canRequest,
  }
) => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={0}
    height="100vh"
  >
    <Card
      sx={{
          width: 360,
        }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Typography gutterBottom>パスワード登録</Typography>
          <TextField
            value={password}
            onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                  >,
              ) => {
                updatePassword(event.target.value);
              }}
            type="password"
            fullWidth
            variant="standard"
            label="パスワード"
            size="small"
          />
          <TextField
            value={passwordConfirmation}
            onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                  >,
              ) => {
                updatePasswordConfirmation(event.target.value);
              }}
            type="password"
            fullWidth
            variant="standard"
            label="パスワード確認"
            size="small"
          />
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          onClick={submitButtonOnClick}
          disabled={!canRequest}
          fullWidth
          variant="outlined"
        >
          パスワードを変更する
        </Button>
      </CardActions>
    </Card>
  </Stack>
  ));

export default PasswordPresenter;
