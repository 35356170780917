import { useSnackbar } from 'notistack';
import { FC, memo, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useActivationQuery } from 'src/hooks/useActivation.query';
import { Props as PresenterProps } from 'src/presenters/Activation.presenter';

type Props = {
  token: string;
  Presenter: FC<PresenterProps>
}

const ActivationComponent: FC<Props> = memo((
  {
    token,
    Presenter
  }
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isError, isLoading } = useActivationQuery({
    token
  });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isError) return;

    enqueueSnackbar('エラーが発生しました', {
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
    });
    queryClient.clear();
    navigate('/');
  }, [isError]);

  useEffect(() => {
    if (!data) return;

    navigate('/');
  }, [data]);

  return (
    <Presenter
      open={loading}
    />
  );
});

export default ActivationComponent;
